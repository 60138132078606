export default class CountryModel {
    
    async GetCurrCountryDetails() {
        let url = `https://ipapi.co/json/`

        try {
            const response = await fetch(url)

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("get request failed ", err)
            return err
        }
    }
}