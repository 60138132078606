import "./Contests.css"
import React from "react"
import Contest from "./contest/Contest"
import ContestModel from "./../../../model/ContestModel"
// import OwlCarousel from 'react-owl-carousel'
// import 'owl.carousel/dist/assets/owl.carousel.css'
// import 'owl.carousel/dist/assets/owl.theme.default.css'
import Swiper from "swiper"
import { Navigation } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import ContestLeaderBoard from "./../contestLeaderBoard/ContestLeaderBoard"
import { PulseLoader } from "react-spinners"
import GoBackButton from "../../common/goBackButton/GoBackButton"

export default class Contests extends React.Component {
  constructor() {
    super()
    this.state = {
      fetchingContests: true,
      contests: null,
      selectedContestId: null,
      selectedContest: null,
      isLiveContest: true,
      showLeaderBoard: false,
    }

    this.contestModel = new ContestModel()

    this.toggleContestType = this.toggleContestType.bind(this)
    this.showLeaderBoard = this.showLeaderBoard.bind(this)
    this.hideLeaderBoard = this.hideLeaderBoard.bind(this)
  }

  componentWillUnmount() {
    this.setState({})
  }

  componentDidMount() {
    this.getContests()
  }

  componentDidUpdate() {
    new Swiper(".contest-swiper", {
      // Optional parameters
      direction: "horizontal",
      loop: false,
      modules: [Navigation],

      // If we need pagination
      // pagination: {
      //   el: '.swiper-pagination',
      // },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // And if we need scrollbar
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      // },

      slidesPerView: 3,
      spaceBetween: 25, // Optional: Set space between slides
      breakpoints: {
        // Responsive breakpoints
        320: {
          slidesPerView: 1, // Display 1 slide on screens less than 320px wide
        },
        768: {
          slidesPerView: 2, // Display 1 slide on screens less than 768px wide (mobile)
        },
        769: {
          slidesPerView: 3, // Display 2 slides on screens 769px and wider
        },
      },
    })
  }

  async getContests() {
    const contests = await this.contestModel.GetContests()
    this.setState({
      contests: await contests,
      fetchingContests: false,
      selectedContestId: null,
    })
  }

  toggleContestType() {
    this.setState({
      isLiveContest: !this.state.isLiveContest,
      fetchingContests: true,
    })
    this.getContests()
  }

  showLeaderBoard(contest) {
    this.setState({
      selectedContest: contest,
      showLeaderBoard: true,
    })
  }

  hideLeaderBoard() {
    this.setState({
      showLeaderBoard: false,
    })
  }

  render() {
    const contests = []
    if (this.state.isLiveContest) {
      if (this.state.contests?.live_contest?.data) {
        for (let contest of this.state.contests.live_contest.data) {
          contests.push(
            <Contest
              onClick={this.props.onShowSummary}
              key={`contest-${contest.id}`}
              contest={contest}
              type="live"
              value={123}></Contest>
          )
        }
      }
      if (this.state.contests?.upcoming_contest?.data) {
        for (let contest of this.state.contests.upcoming_contest.data) {
          contests.push(
            <Contest
              key={`contest-${contest.id}`}
              contest={contest}
              type="upcoming"></Contest>
          )
        }
      }
    } else {
      if (this.state.contests?.past_contest?.data) {
        for (let contest of this.state.contests.past_contest.data) {
          contests.push(
            <Contest
              key={`contest-${contest.id}`}
              contest={contest}
              type="past"
              onClick={this.showLeaderBoard}></Contest>
          )
        }
      }
    }

    if (contests.length === 0) {
      contests.push(<div key={`no-contests-${Math.random()}`} className="no-contests">No Contests</div>)
    }

    let data = ""
    let loading = (
      <PulseLoader
        key={`contest-loader-${Math.random()}`}
        loading="true"
        color="grey"
        aria-label="Loading Spinner"
        data-testid="loader"></PulseLoader>
    )
    if (!this.state.fetchingContests) {
      data = (
        <div key={`contest-swiper-${Math.random()}`} className="swiper contest-swiper">
          <div className="swiper-wrapper">{contests}</div>

          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
      )

      loading = ""
    }

    const contestsHtml = (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="title">
                <GoBackButton onClick={this.props.onGoBack}></GoBackButton>
                {this.state.isLiveContest ? "Live & Upcoming" : "Past Contests"}
                {loading}
              </h3>
            </div>
            <div className="col-md-6">
              <button
                type="button"
                className={`${this.state.isLiveContest ? "past-btn" : "live-btn"
                  }`}
                onClick={this.toggleContestType}>
                {this.state.isLiveContest ? "Past Contests" : "Live Contests >"}
              </button>
            </div>
          </div>
        </div>
        {data}
      </>
    )

    if (this.state.showLeaderBoard) {
      return (
        <ContestLeaderBoard
          contest={this.state.selectedContest}
          onHideLeaderBoard={this.hideLeaderBoard}></ContestLeaderBoard>
      )
    } else {
      return contestsHtml
    }
  }
}
