import React from "react"

import "./ProfileSlider.css"
import InnovationsModel from "../../../model/InnovationsModel"
import { PulseLoader } from "react-spinners"
import Feed from "./Feed/Feed"

export default class ProfileSlider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      passedProfiles: this.props.passedProfiles,
      submittedInnovations: null,
      loading: true,
      page: 1,
      limit: 7,
      hasMore: true,
      pageLoadedForFirstTime: true,
    }

    this.feedsContainerRef = React.createRef()

    this.innovationsModel = new InnovationsModel()

    this.handleScroll = this.handleScroll.bind(this)
    this.fetchSubmittedInnovations = this.fetchSubmittedInnovations.bind(this)
  }

  componentDidMount() {
    if (this.state.passedProfiles.length === 0) {
      // Fetch innovations
      this.fetchSubmittedInnovations()
    } else {
      this.setState(
        {
          submittedInnovations: this.state.passedProfiles,
          loading: false,
          pageLoadedForFirstTime: true,
        },
        () => {
          // Initialize Swiper
        }
      )
    }
  }

  componentWillUnmount() { }

  handleScroll() {
    const container = this.feedsContainerRef.current
    if (container) {
      const scrollTop = container.scrollTop
      const scrollHeight = container.scrollHeight
      const clientHeight = container.clientHeight

      if (scrollTop + clientHeight >= scrollHeight - 100) {

        if (!this.state.loading) {
          this.fetchMoreInnovations()
        }
      }
    }
  }

  async fetchSubmittedInnovations() {
    this.setState({ loading: true }, async () => {
      const resp = await this.innovationsModel.GetSubmittedInnovations()
      this.setState(
        {
          submittedInnovations: resp.data,
          loading: false,
          page: 2,
          pageLoadedForFirstTime: false,
        },
        async () => {
          // Initialize Swiper
        }
      )
    })
  }

  async fetchMoreInnovations() {
    if (!this.state.hasMore) return

    console.log(
      "this.state.submittedInnovations ",
      this.state.submittedInnovations
    )
    this.setState({ loading: true }, async () => {
      try {
        const resp = await this.innovationsModel.GetSubmittedInnovations(
          this.state.page,
          this.state.limit
        )

        let hasMore = true
        if (this.state.page * this.state.limit >= resp.total_feeds) {
          hasMore = false
        }

        let newSubmittedInnovations = this.state.submittedInnovations

        if (resp.data !== null) {
          newSubmittedInnovations = this.state.submittedInnovations.concat(
            resp.data
          )
        }

        this.setState(
          (prevState) => {
            return {
              submittedInnovations: newSubmittedInnovations,
              page: prevState.page + 1,
              hasMore: hasMore,
              loading: false,
            }
          },
          async () => {
            console.log(
              "this.state.submittedInnovations ",
              this.state.submittedInnovations
            )
          }
        )
      } catch (error) {
        console.error("Failed to fetch more innovations:", error)
        this.setState({
          loading: false,
          error: "Failed to load more innovations. Please try again later.",
        })
      }
    })
  }

  render() {
    let slides = ""

    if (this.state.submittedInnovations !== null) {
      slides = this.state.submittedInnovations.map((innovation, index) => {
        return <Feed key={`${index}_feed_list`} feed={innovation} />
      })
    } else {
      return <></>
    }

    let loader = ""
    if (this.state.loading) {
      loader = (
        <PulseLoader
          loading="true"
          color="grey"
          aria-label="Loading Spinner"
          data-testid="loader"></PulseLoader>
      )

      slides.push(loader)
    }
    return (
      <div className="challenge-box">
        <h1 className="challenges-title">Submitted Challenges</h1>
        <div
          ref={this.feedsContainerRef}
          className="feeds-container container-fluid"
          onScroll={this.handleScroll}>
          {slides}
        </div>
      </div>
    )
  }
}
