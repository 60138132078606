import "./help.css"
import React from "react"

export default class Help extends React.Component {

    render() {
        return (
            <>
                <h4>What is STOGO WORLD ?</h4>
                <div className="cont">
                    <p>
                        Tachyon360, is introducing an exciting approach to empower students in the UAE.  STOGO WORLD is a fun and interactive platform that transforms students from mere users to CREATORS & INNOVATORS. This innovative academic platform engages students in real-world challenges, quizzes, and gamified experiences in STEM and Arts.
                    </p>
                    <h5>How  STOGO WORLD benefits students ?</h5>
                    <p>
                        This platform encourages gamified education and awareness, inspiring students to actively participate and unleash their innovation. Through the program, students earn points for their creative achievements, which they can exchange for real-world benefits like Higher Education, Health Care, Sports Training, Outdoor Games, and Educational Goodies. This not only makes learning enjoyable but also provides tangible incentives that contribute to their overall development and well-being.
                    </p>
                    <h5>Why  STOGO WORLD  ?</h5>
                    <p>
                        STOGO WORLD is vital for students aged 6-17, providing a transformative, gamified learning experience in schools. With educator support, the platform, ensures easy accessibility. Real-world challenges in STEM and Arts enhance knowledge, addressing digital well-being, reducing screen time, and instilling environmental responsibility, empowering students for holistic success.
                    </p>
                    <h5>Goals and Outcome</h5>
                    <ul>
                        <li>Foster Creativity, Innovations & Leadership Qualities</li>
                        <li>Enhance Learning Through Gamification</li>
                        <li>Develop Essential Life Skills</li>
                        <li>Excel in STEM and Arts Education</li>
                        <li>Promote Digital Well-Being & Screen Time Reduction</li>
                        <li>Initiate STOGO CLUBs with Expert Mentoring</li>
                        <li>Award Aqdar STOGO Badges as a token of encouragement</li>
                        <li>Recognizing and celebrating students' accomplishments</li>
                    </ul>
                    <h5>Road Map</h5>
                    <ol>
                        <li>Student Login to STOGO WORLD : Begin your STOGO adventure by logging in and exploring a world of learning and challenges.</li>
                        <li>Monthly Task Submissions: Showcase your skills and creativity by submitting videos of monthly tasks assigned on the platform.</li>
                        <li>Weekly Quizzes on Awareness Campaigns: Stay engaged and informed by participating in weekly quizzes focused on various awareness campaigns.</li>
                        <li>Earn Points for Tasks and Quizzes: Accumulate points for each completed task and quiz. The more you participate, the more points you earn!</li>
                        <li>Redeem Points: Your hard-earned points can be redeemed with our diverse partners, including universities, sports centers, health facilities, educational product suppliers, and more.</li>
                        <li>STOGO CHAMP Badges: Stand out as a top performer and achiever by earning STOGO CHAMP Badges.</li>
                        <li>Yearly Special Rewards: As a yearly top performer, enjoy exclusive rewards and certificates, celebrating your commitment and accomplishments on the STOGO platform.</li>
                    </ol>
                </div>
            </>
        )
    }
}