import LocalDataModel from "./LocalDataModel"

export default class SettingsModel {
    constructor() {
        this.localDataModel = new LocalDataModel()
    }

    get settings() {
        let strSettings = this.localDataModel.GetLocalData().Settings

        return JSON.parse(strSettings)
    }
}