export default class LocalDataModel {

    GetLocalData() {
        if (localStorage.getItem("stogo_world")) {
            const localData = localStorage.getItem("stogo_world")
            return JSON.parse(localData)
        } else {
            console.error("local storage not working!")
            return false
        }
    }

    SetLocalData(value) {
        try {
            localStorage.setItem("stogo_world", JSON.stringify(value))

            return true
        } catch (err) {
            console.error("Could not write to local storage!")
            return false
        }
    }
}