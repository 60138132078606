import React from "react"

export default class Button extends React.Component {
    constructor(param) {
        super(param)
        this.state = {
            type: param.type
        }
    }

    render() {
        if (this.state.type === "live") {
            return <button onClick={this.props.onClick} type="button" className="play-btn" data-quiz-id="quiz1">Play</button>
        }
        if (this.state.type === "past") {
            return <button onClick={this.props.onClick} type="button" className="play-btn">LEADER BOARD</button>
        }
        if (this.state.type === "upcoming") {
            return <button type="button" className="upcoming-btn">upcoming</button>
        }

        return null
    }
}