import React from "react";
import "./GoBackButton.css"

export default class GoBackButton extends React.Component {
    render() {
        return (
            <button type="button" className="go-back" onClick={this.props.onClick}>
                <i className="fa fa-angle-left" aria-hidden="true"></i>
            </button>
        )
    }
}