import InnovationsModel from "../../model/InnovationsModel"
import Innovation from "../innovation/Innovation"
import InnovationForm from "../innovationForm/InnovationForm"
import "./Innovations.css"
import { PulseLoader } from "react-spinners"
import React from "react"
import InnovationsSlider from "./innovationsSlider/InnovationsSlider"

export default class Innovations extends React.Component {
  innovationsModel

  constructor(props) {
    super(props)
    this.innovationsModel = new InnovationsModel()

    this.state = {
      innovations: [],
      innovation: null,
      innovation_count: 0,
      loading: true,
    }

    this.setSelectedInnovation = this.setSelectedInnovation.bind(this)
    this.fetchInnovations = this.fetchInnovations.bind(this)
  }

  componentDidMount() {
    this.fetchInnovations()
  }

  setSelectedInnovation(args) {
    this.setState({ innovation: null, innovation_count: 0 })
    setTimeout(() => {
      this.setState({
        innovation: args.innovation,
        innovation_count: args.count,
      })
    }, 100)
  }

  async fetchInnovations() {
    this.setState({ loading: true }, async () => {
      let innovations = await this.innovationsModel.GetInnovations()
      this.setState({
        innovations: innovations,
        loading: false,
      })
    })
  }

  render() {
    let loading = ""
    if (this.state.loading) {
      loading = (
        <PulseLoader
          loading="true"
          color="grey"
          aria-label="Loading Spinner"
          data-testid="loader"></PulseLoader>
      )
    }

    let newInnovations = this.state.innovations.filter((innovation) => innovation.is_submitted === "0")

    return (
      <>
        {loading}
        {(() => {
          let count = 0
          let rows = []
          console.log("newInnovations.length ", newInnovations.length)
          if (newInnovations.length === 0) {
            // return <div className="no-innovations">No Innovations</div>
            return
          } else {
            for (const innovation of newInnovations) {
              count++
              rows.push(
                <Innovation
                  key={innovation.id}
                  count={count}
                  innovation={innovation}
                  onClick={this.setSelectedInnovation}></Innovation>
              )
            }

            let innovationForm
            if (this.state.innovation !== null) {
              innovationForm = (
                <div className="right">
                  <InnovationForm
                    innovation={this.state.innovation}
                    innovation_count={this.state.innovation_count}
                    onSubmit={this.fetchInnovations}></InnovationForm>
                </div>
              )
            }

            return (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="left">
                      <div className="accordion custom-accordion" id="accordionInnovations">
                        {rows}
                      </div>
                    </div>

                    {innovationForm}

                  </div>
                </div>
              </>
            )
          }
        })()}

        <div className="row">
          <div className={`col-12 ${newInnovations.length === 0 ? '' : 'mt-4'}`}>
            <InnovationsSlider onCancelSubmission={this.fetchInnovations}></InnovationsSlider>
          </div>
        </div>
      </>
    )
  }
}
