import ConfigModel from "./ConfigModel";
import UserModel from "./UserModel";
import CryptoJS from "crypto-js";

export default class QuizModel {
    configModel
    userModel
    constructor() {
        this.configModel = new ConfigModel()
        this.userModel = new UserModel()
        this.cryptoJs = CryptoJS
    }

    async GetContestQuestions(contestId) {
        let url = `${this.configModel.api}get_questions_by_contest`
        let data = {
            access_key: "8525",
            language_id: "34",
            contest_id: contestId
        }
        let userData = this.userModel.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }

    }

    decryptAnswer(encrypted_json_string) {
        this.userModel.GetUserFromLocal()
        let key = "1tm0xArPIXgx4VIyv4QmVkL3We13"
        let obj_json = encrypted_json_string
        let encrypted = obj_json.ciphertext
        let iv = this.cryptoJs.enc.Hex.parse(obj_json.iv)
        key += "0000"
        key = this.cryptoJs.enc.Utf8.parse(key)
        try {
            let decrypted = this.cryptoJs.AES.decrypt(encrypted, key, {
                iv: iv,
            }).toString(this.cryptoJs.enc.Utf8)
            return decrypted
        } catch (error) {
            console.error(error)
        }
    }
}